import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import React from 'react';
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';


function SwitchLanguage({ t }) {
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

    return (
        <ButtonGroup variant="text">
            <Button onClick={() => changeLanguage("en")}>En</Button>
            <Button onClick={() => changeLanguage("zh")}>中</Button>
        </ButtonGroup>
    )
}
export default withTranslation()(SwitchLanguage);