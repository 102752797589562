import React, {useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Container from '@mui/material/Container'

export default function PrivacyPolicy() {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <Box width={"100%"} bgcolor={"#FADDC6"} paddingTop={20}>
            <Container maxWidth="md">
                <h1>
                    Levav Privacy Policy
                </h1>
                <h4>
                    Welcome to Levav, operated by Kadima Technology Limited. At Levav, we value your privacy and strive to protect your personal data in compliance with the Personal Data (Privacy) Ordinance (Cap. 486) (the "PDPO").
                </h4>
                <h2>
                    1. Collection of Personal Data
                </h2>
                <h4>
                    We may collect personal data, including but not limited to, your name, date of birth, gender, email address, school information, and contact details. This data is collected to provide services, customer support, and fulfill any other purposes as stated herein.
                </h4>
                <h2>
                    2. Use of Personal Data
                </h2>
                <h4>
                    We will use the personal data you provide for the following purposes:
                </h4>
                <ol type="A">
                    <h4><li>To register and manage your Levav account.</li></h4>
                    <h4><li>To provide and maintain the Levav platform's services.</li></h4>
                    <h4><li>To communicate with you about updates, promotions, and news related to Levav.</li></h4>
                    <h4><li>To ensure the platform's safety and integrity, including detecting and preventing fraudulent activities.</li></h4>
                </ol>
                <h2>
                    3. Disclosure of Personal Data
                </h2>
                <h4>
                    Your personal data will be kept confidential, but we may provide such information to the following parties for the purposes set out in this policy:
                    <ol type="A">
                    <h4><li>Our subsidiaries, affiliates, and partners.</li></h4>
                    <h4><li>Third-party service providers engaged by us to assist with the delivery of our services.</li></h4>
                    <h4><li>Law enforcement agencies, where required by applicable laws and regulations.</li></h4>
                    </ol>
                </h4>
                <h2>
                    4. Data Security
                </h2>
                <h4>
                    We implement various security measures to ensure the safety of your personal data. These measures are designed to protect your data from unauthorized access, alteration, and disclosure.
                </h4>
                <h2>
                    5. Your Rights
                </h2>
                <h4>
                    In accordance with the PDPO, you have various rights and you may, for example:
                </h4>
                <ol type="A">
                    <h4><li>check whether we hold any of your personal data;</li></h4>
                    <h4><li>access your personal data held by us; and</li></h4>
                    <h4><li>require us to correct any personal data which is inaccurate.</li></h4>
                </ol>
                <h2>
                    6. Third-party Links
                </h2>
                <h4>
                    Our platform may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites.
                </h4>
                <h2>
                    7. Changes to This Policy
                </h2>
                <h4>
                    We may revise this Privacy Policy from time to time. Any changes will be posted on this page, and, where appropriate, notified to you by email.
                </h4>
                <h2>
                    8. Marketing Information
                </h2>
                <h4>
                    We may send you marketing materials relating to our services by email from time to time. You may withdraw your consent for us to send such marketing materials to you at any time by directly contacting us (see below).</h4>
                <h2>
                    9. Contacting Us
                </h2>
                <h4>
                    If you have any questions, concerns, or requests relating to this Privacy Policy, or if you wish to exercise any of your rights under the PDPO, or if you wish to unsubscribe to our marketing materials being sent to you via email, please contact us at [info@kadimatech.hk].
                </h4>
            </Container>
        </Box>
    );


}