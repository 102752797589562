import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import React from 'react';
import brush from '../../sources/Graph/Brush_green.png';
import image_Intro from '../../sources/Graph/Image_Intro.png'
import Link from '@mui/material/Link'
import GooglePlayLogo from '../../sources/Icon/GooglePlay.png';
import AppStoreLogo from '../../sources/Icon/AppStore.png';

export default function Introduction() {
    const { t } = useTranslation();

    return (
        <Grid container direction="row"  sx={{
                justifyContent: "center",
                alignItems: "center"
            }}>

            <Grid item xs={4} container direction={'column'} >
                <Typography fontFamily={"Lava Telugu"} fontSize={"64px"} fontWeight={700} fontStyle={"normal"} color='white' py={3} sx={{ textShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)', lineHeight: '100%', position: 'relative', backgroundImage: `url(${brush})`, backgroundSize: '270px', backgroundPosition: 'left', backgroundRepeat: 'no-repeat', backgroundPositionY: 75 }}>
                    {t('Introduction.title')}
                </Typography>

                <Typography color='#4E433C' fontSize={"20px"} fontStyle={"normal"} fontWeight={500} lineHeight={'128%'} >
                    {t('Introduction.text')}
                </Typography>

                <Grid item container paddingTop={10} spacing={2} direction={'column'}>
                    <Grid item>
                    <Link href="https://www.apple.com/hk/app-store/">
                        <img
                            src={AppStoreLogo}
                            alt="AppStore"
                            width="50%"
                            height="50%"
                        />
                    </Link>
                    </Grid>
                    <Grid item>
                    <Link href='https://play.google.com/store/games'>
                        <img
                            src={GooglePlayLogo}
                            alt="GooglePlay"
                            width="50%"
                            height="50%"
                        />
                    </Link>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={8}>
            <img
                src={image_Intro}
                alt="imag_Intro"
                width="100%"
                object-fit="contain"
            />
            </Grid>
            
            
            

            
        </Grid>
    );
}