import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import feature1 from '../../sources/Graph/feature_new_1.png'
import feature2 from '../../sources/Graph/feature_new_2.png'
import feature3 from '../../sources/Graph/feature_new_3.png'
import feature4 from '../../sources/Graph/feature_new_4.png'
import feature5 from '../../sources/Graph/feature_new_5.png'
import feature6 from '../../sources/Graph/feature_new_6.png'
import feature7 from '../../sources/Graph/feature_new_7.png'

function Features() {
  const { t } = useTranslation();
  
  const features = [
    { img: feature1, text: 'Features.features1' },
    { img: feature2, text: 'Features.features2' },
    { img: feature3, text: 'Features.features3' },
    { img: feature4, text: 'Features.features4' },
    { img: feature5, text: 'Features.features5' },
    { img: feature6, text: 'Features.features6' },
    { img: feature7, text: 'Features.features7' },
  ];

  return (
    <Box width="100%" align='center'>
      <Typography fontFamily="Noto Sans TC" textAlign="center" fontSize="36px" fontWeight={600} fontStyle="normal" color='#7DC4A2' py={3} sx={{ textShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)', lineHeight: '100%', position: 'relative'}}>
        {t('Features.title')}
      </Typography>

      <Grid container spacing={2} justifyContent="center">
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={3} key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
              <img 
                src={feature.img}
                alt={`feature${index + 1}`}
                style={{ width: '100%', maxWidth: '150px', objectFit: 'contain' }}
              />
            </Box>
            <Typography>
              {t(feature.text)}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Features;