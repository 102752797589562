import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import brush from '../../sources/Graph/Brush_purple.png';
import levav_white_logo from '../../sources/Logo/LEVAV white logo.png'
import levav_image from '../../sources/Graph/LEVAV_image.png'
import levav_1 from '../../sources/Graph/tag1.png'
import levav_2 from '../../sources/Graph/tag2.png'



function Levav() {
  const { t } = useTranslation();

  return (
    <Box width={"100%"} align='center' >

      <Typography fontFamily={"Noto Sans TC"} textAlign={"center"} fontSize={"36px"} fontWeight={600} fontStyle={"normal"} color='#7DC4A2' py={3} sx={{ textShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)', lineHeight: '100%', position: 'relative', backgroundImage: `url(${brush})`, backgroundSize: 'cover', backgroundPosition: 'left', backgroundRepeat: 'no-repeat', backgroundPositionY: 55 }}>
          {t('Levav.title')}
      </Typography>

      <Grid container spacing={0} direction="row" maxWidth="xl" py={5} pb={2}
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}>
        <Grid item xs={1.5}>
          <img
            src={levav_white_logo}
            alt="LEVAV"
            width="100%"
            object-fit="contain"
          />
        </Grid>
        <Grid item xs={1}>
          <Typography fontFamily={"Lava Devanagari"} color={"#4E433C"} fontSize={40} fontWeight={500} lineHeight={"128%"}>LEVAV</Typography>
        </Grid>
      </Grid>

      <Box pb={20} sx={{position:"relative", }}>
        <Box>
          <img 
            src={levav_image}
            alt="image"
            width="30%"
            object-fit="contain"
          
          />
        </Box>
        {/*
        <Box justifyContent='flex-start' sx={{
                position: 'absolute',
                bottom: '30%', // Adjust this value to move character up or down
                left: '7%', // Adjust this value to move character left or right
                height: 'auto'
                
            }}>
          <Box pb={4}
            component="img"
            maxWidth= '40%' // Adjust size as needed
            alt="levav_1"
            src={levav_1}>
          </Box>
          <Typography fontFamily={"Noto Sans TC"} color={"#4E433C"} fontSize={32} fontWeight={400} lineHeight={"128%"}>
            {t('Levav.text1_1')}
          </Typography>
          <Typography fontFamily={"Noto Sans TC"} color={"#4E433C"} fontSize={32} fontWeight={400} lineHeight={"128%"}>
            {t('Levav.text1_2')}
          </Typography>
          
        </Box>
        */}
        <Grid container direction="column" columns={5} sx={{
                justifyContent: "center",
                alignItems: "flex-start",
                position: 'absolute',
                bottom: '20%', // Adjust this value to move character up or down
                left: '-3%', // Adjust this value to move character left or right
                height: 'auto'
                
            }}>

              <Box pb={4}
                component="img"
                maxWidth= '10%' // Adjust size as needed
                alt="levav_1"
                src={levav_1}>
              </Box>
              <Typography fontFamily={"Noto Sans TC"} color={"#4E433C"} fontSize={32} fontWeight={400} lineHeight={"128%"}>
                {t('Levav.text1_1')}
              </Typography>
              <Typography fontFamily={"Noto Sans TC"} color={"#4E433C"} fontSize={32} fontWeight={400} lineHeight={"128%"}>
                {t('Levav.text1_2')}
              </Typography>
        </Grid>

        <Grid container direction="column" columns={5} sx={{
                justifyContent: "center",
                alignItems: "flex-start",
                position: 'absolute',
                top: '1%', // Adjust this value to move character up or down
                right: '-80%', // Adjust this value to move character left or right
                height: 'auto'
                
            }}>

              <Box pb={4}
                component="img"
                maxWidth= '10%' // Adjust size as needed
                alt="levav_2"
                src={levav_2}>
              </Box>
              <Typography fontFamily={"Noto Sans TC"} color={"#4E433C"} fontSize={32} fontWeight={400} lineHeight={"128%"}>
                {t('Levav.text2_1')}
              </Typography>
              <Typography fontFamily={"Noto Sans TC"} color={"#4E433C"} fontSize={32} fontWeight={400} lineHeight={"128%"}>
                {t('Levav.text2_2')}
              </Typography>
        </Grid>
        

      </Box>
    </Box>
  );
}

export default Levav;