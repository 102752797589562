import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ContactUs() {
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("")
  const handleSubmit = (e) => {
    e.preventDefault();
    window.location.href = `mailto:info@kadimatech.hk?subject=Contact Us - ${subject} - from ${name}&body=${message} - From: ${email}`;
  };

  const fieldsAreFilled = () => {
    return name !== "" && email !== "" && subject !== "" &&message !== "";
  };
  return (
    <Grid item container xs={12} display={'flex'} justifyContent={'center'}>
      <Box id="contactUs" maxWidth={900} sx={{ width: '70%', border: 0, borderRadius: '40px', bgcolor: '#FFF7F2'}}>
        <Grid container position={'relative'}>

          <Grid item xs={12} md={6} p={5} display={'flex'} flexDirection={'column'} alignItems={'flex-start'} >
            <Grid item xs={3}>
              <Box sx={{ width: 250, height: 100 }} color='#7DC4A2' display={'flex'} justifyContent={'center'} alignItems={'center'} >
                <Typography sx={{ fontFamily: 'NotoSerif-Bold', fontSize: '42px' }}>{t('ContactUs.title')}</Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>

            </Grid>
            <Grid item xs={3}> 
              <Button
                variant="contained"
                sx={{
                  fontFamily: "HelveticaNeue",
                  fontSize: "24px",
                  width: '150px',
                  border: 0,
                  borderRadius: '20px',
                  borderColor: '#D19676',
                  backgroundColor: '#D19676',
                  color: "white",
                  '&:hover': {
                    border: 1,
                    borderColor: '#f7eae4',
                    backgroundColor: '#cd8c69',
                  },
                  '&:disabled': {
                    borderColor: '#e4c1af',
                    backgroundColor: '#e4c1af',
                  },
                }}
                onClick={handleSubmit}
                disabled={!fieldsAreFilled()}
              >
                {t('ContactUs.send')}
              </Button>
            </Grid>
            
          </Grid>

          <Grid item xs={12} md={6} p={5} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
            <Box p={1} sx={{ width: '100%' }}>
              <TextField required id="name" label={t('ContactUs.name')} fullWidth sx={{ backgroundColor: 'white', borderRadius: '5px' }} value={name} onChange={e => setName(e.target.value)} />
            </Box>
            <Box p={1} sx={{ width: '100%' }}>
              <TextField required id="email" label={t('ContactUs.email')} fullWidth sx={{ backgroundColor: 'white', borderRadius: '5px' }} value={email} onChange={e => setEmail(e.target.value)} />
            </Box>
            <Box p={1} sx={{ width: '100%' }}>
              <TextField required id="subject" label={t('ContactUs.subject')} fullWidth sx={{ backgroundColor: 'white', borderRadius: '5px' }} value={subject} onChange={e => setSubject(e.target.value)} />
            </Box>
            <Box p={1} sx={{ width: '100%' }}>
              <TextField required id="message" label={t('ContactUs.message')} fullWidth multiline rows={5} sx={{ backgroundColor: 'white', borderRadius: '5px' }} value={message} onChange={e => setMessage(e.target.value)} />
            </Box>
          </Grid>

          
          
        </Grid>
      </Box>
    </Grid>
  );
}