import { Button, Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';

import LevavLogo from '../../sources/Logo/LEVAV Logo.png';
import MindverseLogo from '../../sources/Logo/Mindverse Logo.png';
import Link from '@mui/material/Link'
import GooglePlayLogo from '../../sources/Icon/GooglePlay.png';
import AppStoreLogo from '../../sources/Icon/AppStore.png';
import FacebookLogo from '../../sources/Icon/FacebookIcon.png';
import InstagramLogo from '../../sources/Icon/InstagramIcon.png';
import TwitterLogo from '../../sources/Icon/TwitterIcon.png';

export default function Footer() {

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid container direction="column"
      sx={{
        justifyContent: "flex-start",
        alignItems: "center",
      }}>
      <Grid item container xs={12} direction="column" sx={{ justifyContent: "flex-start", alignItems: "flex-start" }}>
        <Grid item py={7} spacing={2} container direction="row" sx={{justifyContent: "flex-start", alignItems: "center" }}>
          <Grid item xs={6} md={2}>
            <img
              src={LevavLogo}
              alt="LEVAV"
              width="100%"
              object-fit="contain"
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <img
              src={MindverseLogo}
              alt="Mindverse"
              width="100%"
              object-fit="contain"
            />
          </Grid>
        </Grid>

        <Grid py={3}>
          <Typography fontFamily={"Lava Devanagari"} color={"#D19676"} fontSize={20} fontWeight={500}>
          {t('Footer.text1')}
          </Typography>
        </Grid>
        

        <Grid item py={3} container spacing={0} direction={'row'}>
          <Grid item xs={6} md={2.5}>
            <Link href="https://www.apple.com/hk/app-store/">
              <img
                src={AppStoreLogo}
                alt="AppStore"
                width="auto"
                height="68px"
              />
            </Link>
          </Grid>
          <Grid item xs={6} md={2.5}>
            <Link href='https://play.google.com/store/games'>
              <img
                src={GooglePlayLogo}
                alt="GooglePlay"
                width="auto"
                height="68px"
              />
            </Link>
          </Grid>
        </Grid>

        <Grid py={3}>
          <Typography fontFamily={"Lava Devanagari"} color={"#D19676"} fontSize={20} fontWeight={500}>
          {t('Footer.text2')}
          </Typography>
        </Grid>

        <Grid item py={3} container spacing={2} direction={'row'} sx={{ justifyContent: "flex-start", alignItems: "flex-start" }}>
          <Grid item xs={4} md={1}>
            <Link href='https://www.facebook.com/'>
              <img
                src={FacebookLogo}
                alt="Facebook"
                width="auto"
                height="50px"
              />
            </Link>
          </Grid>
          <Grid item xs={4} md={1}>
            <Link href='https://www.instagram.com'>
              <img
                src={InstagramLogo}
                alt="Instagram"
                width="auto"
                height="50px"
              />
            </Link>
          </Grid>
          <Grid item xs={4} md={1}>
            <Link href='https://twitter.com'>
              <img
                src={TwitterLogo}
                alt="Twitter"
                width="auto"
                height="50px"
              />
            </Link>
          </Grid>
        </Grid>

      </Grid>
      
      <Grid item container direction="row"
        sx={{
          justifyContent: "space-around",
          alignItems: "center",
        }}>
        <Grid item xs={12} md={4} mb={2} display="flex" justifyContent={{ xs: "center", md: "flex-start" }}>
          <Typography fontFamily={"HelveticaNeue"} fontSize={"14px"} textAlign={'left'}>
              © 2024 Kadima Technology Limited All rights reserved.
          </Typography>
        </Grid>

        <Grid container item xs={0} md={1}></Grid>
        <Grid container item xs={0} md={1}></Grid>
        <Grid container item xs={0} md={1}></Grid>
        <Grid container item xs={0} md={1}></Grid>
        
        <Grid item xs={12} md={4} mb={2} display="flex" justifyContent={{ xs: "center", md: "flex-end" }}>
          <Box display={'flex'} flexDirection={'row'} justifyContent={{ xs: "center", md: "space-between" }}>
            <MuiLink onClick={() => navigate(`/`)} mr={2} sx={{ textDecoration: "none", color: "black", cursor: 'pointer' }}>
              <Typography fontFamily={"HelveticaNeue"} fontSize={"14px"}>
                {t('Footer.TAC')}
              </Typography>
            </MuiLink>
            <Divider orientation="vertical" flexItem />
            <MuiLink onClick={() => navigate(`/PrivacyPolicy`)} ml={2} sx={{ textDecoration: "none", color: "black", cursor: 'pointer' }}>
              <Typography fontFamily={"HelveticaNeue"} fontSize={"14px"}>
                  {t('Footer.PP')}
              </Typography>
            </MuiLink>
              
          </Box>
          </Grid>
      </Grid>
      
    </Grid >
  );
}