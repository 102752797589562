import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import image_1 from '../../sources/Graph/CustomizedService_1.png'
import image_2 from '../../sources/Graph/CustomizedService_2.png'
import image_3 from '../../sources/Graph/CustomizedService_3.png'
import image_4 from '../../sources/Graph/CustomizedService_4.png'

function Gallery_CustomizedService() {
  const { t } = useTranslation();

  return (
    <Box width="100%" align='center'>
      <Typography fontFamily="Noto Sans TC" textAlign="center" fontSize="36px" fontWeight={600} fontStyle="normal" color='#7DC4A2' py={3} sx={{ textShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)', lineHeight: '100%', position: 'relative'}}>
        {t('CustomizedService.title')}
      </Typography>
      <Typography fontFamily="Noto Sans TC" textAlign="center" fontSize="24px" fontWeight={400} color="#99918C" py={3}>
        {t('CustomizedService.text')}
      </Typography>

      <Grid container spacing={2} sx={{ p: 2, borderRadius: '8px' }}>
        <Grid item xs={12} md={4} sx={{ aspectRatio: '1/1' }}>
          <img
            src={image_1}
            alt="image_1"
            style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '4px' }}
          />
        </Grid>
        <Grid item xs={12} md={8} container spacing={2}>
          <Grid item xs={12} sx={{ aspectRatio: '2/1' }}>
            <img
              src={image_2}
              alt="image_2"
              style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '4px' }}
            />
          </Grid>
          <Grid item xs={6} sx={{ aspectRatio: '1/1' }}>
            <img
              src={image_3}
              alt="image_3"
              style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '4px' }}
            />
          </Grid>
          <Grid item xs={6} sx={{ aspectRatio: '1/1' }}>
            <img
              src={image_4}
              alt="image_4"
              style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '4px' }}
            />
          </Grid>
        </Grid>
      </Grid>
      
    </Box>
  );
}

export default Gallery_CustomizedService;