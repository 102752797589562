import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Link as MuiLink } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LevavLogo from '../../sources/Logo/LEVAV Logo.png';
import MindverseLogo from '../../sources/Logo/Mindverse Logo.png';
import ChangeLanguage from './ChangeLanguage.js';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

function AppBar() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const appBarBackgroundColor = "rgba(255, 255, 255, 0.60)";

  const handleNavigation = (sectionId) => {
    if (location.pathname === '/') {
      // If on home page, scroll to section
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      // If on another page, navigate to home page with hash
      navigate(`/#${sectionId}`);
    }
  };

  return (
    <Box sx={{ height: 'auto', width: '100%', backgroundColor: appBarBackgroundColor }}>
      <Grid paddingLeft={5} paddingRight={5} paddingTop={4} paddingBottom={2} container direction="row" sx={{justifyContent: "flex", alignItems: "center" }}>
        <Grid item xs={3} container spacing={2} direction="row" sx={{ justifyContent: "flex-start", alignItems: "center" }}>
          <MuiLink onClick={() => handleNavigation('introduction')} sx={{ cursor: 'pointer' }}>
            <img src={LevavLogo} alt="LEVEV Logo" height="50px" width="auto" />
          </MuiLink>
          <MuiLink onClick={() => handleNavigation('introduction')} sx={{ cursor: 'pointer' }}>
            <img src={MindverseLogo} alt="Mindverse Logo" height="50px" width="auto" />
          </MuiLink>
        </Grid>

        <Grid item xs={9} container gap={2} spacing={2} direction="row" sx={{ justifyContent: "flex-end", alignItems: "center" }}>
          <MuiLink onClick={() => handleNavigation('introduction')} sx={{ textDecoration: "none", color: "black", cursor: 'pointer' }}>
            <Typography sx={{ '&:hover': { textDecoration: 'underline' } }} color="#4e7ba8">
              {t('TopBar.home')}
            </Typography>
          </MuiLink>
          <MuiLink onClick={() => handleNavigation('levav')} sx={{ textDecoration: "none", color: "black", cursor: 'pointer' }}>
            <Typography sx={{ '&:hover': { textDecoration: 'underline' } }} color="#4e7ba8">
              {t('TopBar.levav')}
            </Typography>
          </MuiLink>
          <MuiLink onClick={() => handleNavigation('mindverse')} sx={{ textDecoration: "none", color: "black", cursor: 'pointer' }}>
            <Typography sx={{ '&:hover': { textDecoration: 'underline' } }} color="#4e7ba8">
              {t('TopBar.mindverse')}
            </Typography>
          </MuiLink>
          <MuiLink onClick={() => handleNavigation('contactUs')} sx={{ textDecoration: "none", color: "black", cursor: 'pointer' }}>
            <Typography sx={{ '&:hover': { textDecoration: 'underline' } }} color="#4e7ba8">
              {t('TopBar.contact')}
            </Typography>
          </MuiLink>

          <Box>
            <ChangeLanguage />
          </Box>
          
          <Box>
            <Button href="https://levav.com.hk/cms/" sx={{ color: "#ffffff", backgroundColor: '#65cb9a', borderRadius: 2, textTransform: 'none', '&:hover': { backgroundColor: '#1a9f77' } }}>{t('TopBar.loginBtn')}</Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AppBar;